/* !
 * __    _____ _____      __ _____ _____ ____  _____ _____
 * |  |  |  _  | __  |  __|  |   | |   __|    \| __  | __  |
 * |  |__|     | __ -|_|  |  | | | |__   |  |  | __ -|    -|
 * |_____|__|__|_____|_|_____|_|___|_____|____/|_____|__|__|
 *
 * LAB.JNSDBR
 * Experiments by Jens de Boer
 *
 * If you got a job to offer or you're interested to work with me,
 * feel free to drop me a line: hello@jnsdbr.de
 *
 * @version 1.0
 * @author Jens de Boer <jens@jnsdbr.de>
 */

/**
 * BasicLightBox
 */
class BasicLightBox {
    /**
     *
     * @param {*} content
     * @param {*} opts
     */
    constructor(content) {
        this.content = this.validateContent(content);
        this.element = this.render(this.content, this.opts);
        this.element.addEventListener('click', (event) => {
            this.close();
        }, false);
    }

    /**
     * Creates an element from a HTML string.
     * @param {String} html
     * @param {boolean} children - Return all children instead of the first one.
     * @return {Node}
     */
    toElement(html, children = false) {
        const elem = document.createElement('div');
        elem.innerHTML = html.trim();
        return children === true ? elem.children : elem.firstChild;
    }

    /**
     * Validates and converts content.
     * @param {Node|String} content
     * @return {Array} content - Validated content.
     */
    validateContent(content) {
        const isString = typeof content === 'string';
        const isHTMLElement = content instanceof HTMLElement === true;

        if (isString === false && isHTMLElement === false) {
            throw new Error('Content must be a DOM element/node or string');
        }

        if (isString === true) {
            // String
            return Array.from(this.toElement(content, true));
        } else {
            if (content.children.length === 0) {
                return content;
            } else {
                // HTMLElement
                return Array.from(content.children);
            }
        }
    }

    /**
     * Checks if an element's first child has a specific tag.
     * @param {Node} elem
     * @param {String} tag
     * @return {Boolean} containsTag
     */
    containsTag(elem, tag) {
        const children = elem.children;
        return (children.length === 1 && children[0].tagName === tag);
    }

    /**
     * Checks if a given or any lightbox is visible.
     * @param {?Node} elem
     * @return {Boolean} visible
     */
     visible(elem) {
        elem = elem || document.querySelector('.basicLightbox');
        return (elem != null &&
                elem.ownerDocument.body.contains(elem) === true);
    }

    /**
     * Creates a lightbox element.
     * @param {Array} content
     * @return {Node} HTML Object
     */
    render(content) {
        const elem = this.toElement(`
            <div class="basicLightbox">
                <div class="basicLightbox__placeholder" role="dialog"></div>
            </div>
        `);

        const placeholder = elem.querySelector('.basicLightbox__placeholder');

        // Move content into lightbox placeholder
        if (typeof content === 'Array') {
            content.forEach((child) => placeholder.appendChild(child));
        } else {
            placeholder.appendChild(content.cloneNode());
        }

        // Check if placeholder contains a tag that requires a special treatment
        const img = this.containsTag(placeholder, 'IMG');
        const video = this.containsTag(placeholder, 'VIDEO');
        const iframe = this.containsTag(placeholder, 'IFRAME');

        /*
         * Add special treatment class when it only contains an image,
         * a video or iframe.
         * This class is necessary to center the image, video or iframe.
         */
        if (img === true) elem.classList.add('basicLightbox--img');
        if (video === true) elem.classList.add('basicLightbox--video');
        if (iframe === true) elem.classList.add('basicLightbox--iframe');

        return elem;
    }

    /**
     * Shows a lightbox by appending an element to the DOM.
     * @param {Function} next - Executed when the lightbox starts to show up.
     * @return {Boolean} success
     */
    show(next) {
        document.body.appendChild(this.element);

        // Wait a while to ensure that the class change triggers the animation
        setTimeout(() => {
            requestAnimationFrame(() => {
                this.element.classList.add('basicLightbox--visible');
                if (typeof next === 'function') {
                    return next();
                }
            });
        }, 10);

        return true;
    }

    /**
     * Closes a lightbox by fading the element out and by removing the
     * element from the DOM.
     * @param {Function} next - Executed when the lightbox is fully closed.
     * @return {Boolean} success
     */
    close(next) {
        this.element.classList.remove('basicLightbox--visible');

        setTimeout(() => {
            // Don't continue to remove lightbox when element missing
            if (this.visible(this.element) === false) return next();

            this.element.parentElement.removeChild(this.element);

            if (typeof next === 'function') {
                return next();
            }
        }, 410);

        return true;
    }
}

/**
 * Lab
 */
class Lab {
    /**
     * Default constructor
     */
    constructor() {
        const prompts = document.querySelectorAll('.prompt');
        const images = document.querySelectorAll('img');

        // Disable listeners on mobile
        if (window.innerWidth > 700) {
            prompts.forEach((prompt) => {
                prompt.addEventListener('click', (event) => {
                    this.promptListener(event);
                }, false);
            });

            images.forEach((image) => {
                image.addEventListener('click', this.imageListener);
            });
        }
    }

    /**
     * Listener for image clicks
     * @param {Object} event
     */
    imageListener(event) {
        new BasicLightBox(event.target).show();
    }

    /**
     * Listener for prompt clicks
     * @param {Object} event
     */
    promptListener(event) {
        this.copyToClipboard(event.target.innerHTML);
    }

    /**
     * Copy string to clipboard
     * @param {String} str
     * @return {Object}
     */
    copyToClipboard(str) {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            return navigator.clipboard.writeText(str);
        }
        return Promise.reject('The Clipboard API is not available.');
    }
}


document.addEventListener('DOMContentLoaded', (event) => {
    new Lab();
});
